export default (i18n) => {
  return {
    path: '/dashboard',
    component: () => import('@modules/dashboard/views/IndexView.vue'),
    meta: {
      forAuth: true,
      site: {
        name: i18n.global.t('app.dashboard'),
        t: 'app.dashboard',
      },
      requiredPermissions: [
        'dashboard_conversation_conversation_average',
        'dashboard_conversation_conversation_total',
        'dashboard_conversation_whatsapp_conversation',
        'dashboard_conversation_total_channel',
        'dashboard_conversation_total_category',
        'dashboard_conversation_total_state',
        'dashboard_conversation_recieved_vs_attended',
        'dashboard_messages_average',
        'dashboard_messages_agent_vs_client',
        'dashboard_messages_density',
        'dashboard_agent_client_average',
        'dashboard_agent_client_category_attended_by_agent',
        'dashboard_agent_client_performance_average_by_agent',
        'dashboard_agent_client_total_conversation_by_agent',
        'dashboard_bot_conversation_map',
        'dashboard_bot_frequency_table',
      ],
    },
    children: [
      {
        path: '',
        name: 'dashboard-conversations',
        component: () => import('@modules/dashboard/views/ConversationsView.vue'),
        meta: {
          forAuth: true,
          title: 'dashboard.conversations',
          site: {
            t: 'dashboard.conversations',
          },
          requiredPermissions: [
            'dashboard_conversation_conversation_average',
            'dashboard_conversation_conversation_total',
            'dashboard_conversation_whatsapp_conversation',
            'dashboard_conversation_total_channel',
            'dashboard_conversation_total_category',
            'dashboard_conversation_total_state',
            'dashboard_conversation_recieved_vs_attended',
          ],
        },
      },
      {
        path: 'agents',
        name: 'dashboard-agents',
        component: () => import('@modules/dashboard/views/AgentsView.vue'),
        meta: {
          forAuth: true,
          title: 'dashboard.agents',
          site: {
            t: 'dashboard.agents',
          },
          requiredPermissions: [
            'dashboard_agent_client_average',
            'dashboard_agent_client_category_attended_by_agent',
            'dashboard_agent_client_performance_average_by_agent',
            'dashboard_agent_client_total_conversation_by_agent',
          ],
        },
      },
      {
        path: 'messages',
        name: 'dashboard-messages',
        component: () => import('@modules/dashboard/views/MessagesView.vue'),
        meta: {
          forAuth: true,
          title: 'app.messages',
          site: {
            t: 'app.messages',
          },
          requiredPermissions: [
            'dashboard_messages_average',
            'dashboard_messages_agent_vs_client',
            'dashboard_messages_density',
          ],
        },
      },
      {
        path: 'bot',
        name: 'conversations-ivr',
        component: () => import('@modules/dashboard/views/BotView.vue'),
        meta: {
          forAuth: true,
          title: 'app.bot',
          site: {
            t: 'app.bot',
          },
          requiredPermissions: [
            'dashboard_bot_conversation_map',
            'dashboard_bot_frequency_table',
          ],
        },
      },
      {
        path: 'surveys',
        name: 'survey_plugin',
        component: () => import('@modules/dashboard/views/SurveysView.vue'),
        meta: {
          forAuth: true,
          site: {
            t: 'app.surveys',
          },
          title: 'app.surveys',
        },
      },
      {
        path: 'trivias',
        name: 'trivia_plugin',
        component: () => import('@modules/dashboard/views/TriviasView.vue'),
        meta: {
          forAuth: true,
          title: 'dashboard.agents',
        },
      },
      {
        path: 'appointments',
        name: 'appointment_plugin',
        component: () => import('@modules/dashboard/views/AppointmentsView.vue'),
        meta: {
          forAuth: true,
          title: 'dashboard.agents',
        },
      },
      {
        path: 'marketplace',
        name: 'marketplace_plugin',
        component: () => import('@modules/dashboard/views/MarketplacesView.vue'),
        meta: {
          forAuth: true,
          title: 'dashboard.agents',
        },
      },
    ],
  };
};
